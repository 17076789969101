import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g02'

let batchAddUrl = 'batchAdd'
let aggregationUrl = 'aggregation'
let generateDataUrl = 'generateData'
let tenderStatUrl = 'tenderStats'
let detailTenderStatUrl = 'detailTenderStats'

class G02Service extends RestService {
  batchAdd (g02Models) {
    g02Models.forEach(g02Model => {
      g02Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g02Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  generateData (planStatExaminationGuid) {
    return axios.post(utility.getRestFullUrl(this.resourceName, `${generateDataUrl}?planStatExaminationGuid=${planStatExaminationGuid}`), {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetailTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, detailTenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  aggregation (tenderGuid, year, month) {
    return axios.get(utility.getRestFullUrl(this.resourceName, aggregationUrl), {
      params: {
        tenderGuid,
        year,
        month
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g02Service = new G02Service(resourceName)

export default g02Service
